// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-ai-equity-js": () => import("./../../../src/pages/ai/equity.js" /* webpackChunkName: "component---src-pages-ai-equity-js" */),
  "component---src-pages-all-access-index-js": () => import("./../../../src/pages/all-access/index.js" /* webpackChunkName: "component---src-pages-all-access-index-js" */),
  "component---src-pages-angel-tax-calculator-index-js": () => import("./../../../src/pages/angel/tax-calculator/index.js" /* webpackChunkName: "component---src-pages-angel-tax-calculator-index-js" */),
  "component---src-pages-clair-index-js": () => import("./../../../src/pages/clair/index.js" /* webpackChunkName: "component---src-pages-clair-index-js" */),
  "component---src-pages-company-equity-index-js": () => import("./../../../src/pages/company-equity/index.js" /* webpackChunkName: "component---src-pages-company-equity-index-js" */),
  "component---src-pages-core-index-js": () => import("./../../../src/pages/core/index.js" /* webpackChunkName: "component---src-pages-core-index-js" */),
  "component---src-pages-docs-api-index-js": () => import("./../../../src/pages/docs/api/index.js" /* webpackChunkName: "component---src-pages-docs-api-index-js" */),
  "component---src-pages-how-it-works-index-js": () => import("./../../../src/pages/how-it-works/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-money-allocation-index-js": () => import("./../../../src/pages/money-allocation/index.js" /* webpackChunkName: "component---src-pages-money-allocation-index-js" */),
  "component---src-pages-nerdwallet-index-js": () => import("./../../../src/pages/nerdwallet/index.js" /* webpackChunkName: "component---src-pages-nerdwallet-index-js" */),
  "component---src-pages-nerdwallet-smb-index-js": () => import("./../../../src/pages/nerdwallet-smb/index.js" /* webpackChunkName: "component---src-pages-nerdwallet-smb-index-js" */),
  "component---src-pages-partner-index-js": () => import("./../../../src/pages/partner/index.js" /* webpackChunkName: "component---src-pages-partner-index-js" */),
  "component---src-pages-peach-index-js": () => import("./../../../src/pages/peach/index.js" /* webpackChunkName: "component---src-pages-peach-index-js" */),
  "component---src-pages-portfolio-advice-index-js": () => import("./../../../src/pages/portfolio-advice/index.js" /* webpackChunkName: "component---src-pages-portfolio-advice-index-js" */),
  "component---src-pages-premium-index-js": () => import("./../../../src/pages/premium/index.js" /* webpackChunkName: "component---src-pages-premium-index-js" */),
  "component---src-pages-pull-index-js": () => import("./../../../src/pages/pull/index.js" /* webpackChunkName: "component---src-pages-pull-index-js" */),
  "component---src-pages-retirement-index-js": () => import("./../../../src/pages/retirement/index.js" /* webpackChunkName: "component---src-pages-retirement-index-js" */),
  "component---src-pages-rippling-index-js": () => import("./../../../src/pages/rippling/index.js" /* webpackChunkName: "component---src-pages-rippling-index-js" */),
  "component---src-pages-rsu-index-js": () => import("./../../../src/pages/rsu/index.js" /* webpackChunkName: "component---src-pages-rsu-index-js" */),
  "component---src-pages-sana-index-js": () => import("./../../../src/pages/sana/index.js" /* webpackChunkName: "component---src-pages-sana-index-js" */),
  "component---src-pages-static-index-js": () => import("./../../../src/pages/static/index.js" /* webpackChunkName: "component---src-pages-static-index-js" */),
  "component---src-pages-tax-calculator-index-js": () => import("./../../../src/pages/tax-calculator/index.js" /* webpackChunkName: "component---src-pages-tax-calculator-index-js" */),
  "component---src-pages-wl-status-index-js": () => import("./../../../src/pages/wl/status/index.js" /* webpackChunkName: "component---src-pages-wl-status-index-js" */)
}

